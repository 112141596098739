import { mapObject } from '../helpers.js';
import { css } from '../css/index.js';

const truncateConfig = {
transform() {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  };
}}

export const getTruncateStyle = (styles = {}) => truncateConfig.transform(styles, { map: mapObject })

export const truncate = (styles) => css(getTruncateStyle(styles))
truncate.raw = getTruncateStyle